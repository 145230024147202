$('#sign-up-form').on('submit', function(e) {
  e.preventDefault();

  $.ajax({
    type: $(this).attr('method'),
    url: $(this).attr('action'),
    data: $(this).serialize(),
    success: function(data) {
      if (data.success) {
        $('#sign-up-modal').modal('hide');
        $('#check-inbox-modal').modal('show');
      } else {
        $('#error-messages').html(data.errors.join('<br>'));
      }
    },
    error: function(jqXHR, textStatus, errorThrown) {
      var errorHtml = jqXHR.responseJSON.errors.map(function(error) {
        return '<div class="field_with_errors">' + error + '</div>';
      }).join('');
      $('#error-messages').html(errorHtml);
    }
  });
});

$('#sign-up-modal').on('hidden.bs.modal', function () {
  // Clear error messages when the modal is closed
  $('#error-messages').empty();
  // Clear input fields
  $('#sign-up-form').find('input').val('');
});

$('#toggle-to-login').on('click', function() {
  $('#sign-up-modal').modal('hide');
  $('#login-modal').modal('show');
});

$('#sign-up-modal').on('show.bs.modal', function() {
  $('#login-modal').modal('hide');
});