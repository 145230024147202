document.addEventListener('turbo:load', function () {
  $('body').on('click', '.place-question', function (event) {
    event.preventDefault();
    let questionBeenWrapper = $('.question-been');

    function renderHiddenQuestions(show) {
      $('.place-question').each(function (i, obj) {
        if (show) {
          $('.hidden-question').removeClass('d-none');
        } else {
          $('.hidden-question').addClass('d-none');
        }
      });
    }
    let id = this.id; // This is a permalink
    let question = $(this).parent().attr('id');

    let questionWrapper = $(this);

    $.ajax({
      url: '/places/' + id + '/save_question',
      method: 'POST',
      dataType: 'json',
      data: { question: question },
    }).done(function (data) {
      if (data['success']) {
        if (question == 'back' && data['icon'] == 'fa-check') {
          // Change Interest dropdown to Interested
          let connectionType = $('.connection-type');
          connectionType.val('interested');
        }
        questionWrapper.html('<i class="fa ' + data['icon'] + '"></i>');
        renderHiddenQuestions(
          questionBeenWrapper.children(':first').hasClass('fa-check')
        );
      } else {
        alert(data['message']);
        questionWrapper.html(data['message']);
      }
    });
  });
});
