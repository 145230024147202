import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="group-map"
export default class extends Controller {
  connect() {
    const apiKey =
      'AAPK2720562ea0aa448d9ffd4e56e720ce8ahvYHI83h5qAKHSAmM002AmLLd1zn_dMLh1yJNI8tE-TXpiuacoFZ7NlNo6p6TJQO';
    let marker = {};
    const map = L.map('places-map-group', { zoomSnap: 0.5, maxZoom: 20 });

    let markers = L.featureGroup();

    const basemapLayers = {
      Streets: L.esri.Vector.vectorBasemapLayer('ArcGIS:Streets', {
        apiKey: apiKey,
      }).addTo(map),
      Topographic: L.esri.Vector.vectorBasemapLayer('ArcGIS:Topographic', {
        apiKey: apiKey,
      }).addTo(map),
      Imagery: L.esri.Vector.vectorBasemapLayer('ArcGIS:Imagery', {
        apiKey: apiKey,
      }).addTo(map),
    };
    L.control.layers(basemapLayers, null, { collapsed: false }).addTo(map);

    getPlacesAndRender();

    function getPlacesAndRender(options = {}) {
      let getUrl = window.location;
      let isAdminMap = getUrl.href.includes('admin');
      let params_with_admin_flag = [`admin=${isAdminMap}`].join('&');
      let endpoint = $('#places-map-group').data('url');
      let baseUrl =
        getUrl.protocol +
        '//' +
        getUrl.host +
        endpoint +
        '&' +
        `${params_with_admin_flag}`;

      $.ajax({
        url: baseUrl,
        method: 'GET',
        contentType: 'application/json',
        dataType: 'json',
      }).done(function (data) {
        if (data['places']) {
          let places = data['places'];
          $('.places-count').text(places.length);
          if (places.length > 0) {
            map.fitBounds(
              places.map((place) => {
                return [place['latitude'], place['longitude']];
              }),
              { padding: [10, 10] }
            );
            places.forEach((place) => {
              addPinToMap(
                { latitude: place['latitude'], longitude: place['longitude'] },
                place['link'],
                place['id']
              );
            });
          }
        } else if (data['error']) {
          window.location = '/';
        }
      });
    }

    function addPinToMap(pin, link, id) {
      let lat = Math.round(pin.latitude * 1000000) / 1000000;
      let lon = Math.round(pin.longitude * 1000000) / 1000000;

      marker = L.marker([lat, lon]);
      markers.addLayer(marker);
      map.addLayer(markers);
      if (marker._icon){
        marker._icon.classList.add(id);
      }
      marker.on('click', function (e) {
        loadPlaceDetail(link);
        applySelectedMarkerLogic(e.target);
      });
    }

    function loadPlaceDetail(link) {
      $('#modal-window').find('.modal-content').load(link);
      $('#modal-window').modal();
      // $('#place-detail').load(link, function (response, status, xhr) {
      //   if (xhr.status == '401') {
      //     alert('Please sign in to view place details.')
      //   }
      //   else if (status == 'error') {
      //     var msg = 'Sorry but there was an error: ';
      //     alert(msg + xhr.status + ' ' + xhr.statusText);
      //   } else {
      //     $('.sidebar-menu').hide();
      //     $('#wrapper').removeClass('sb-closed');
      //     $('#place-detail').show();
      //   }
      // });
    }

    function applySelectedMarkerLogic(marker) {
      $('.leaflet-marker-icon').map(function () {
        $(this).removeClass('selected-marker');
      });
      if (!L.DomUtil.hasClass(marker._icon, 'selected-marker')) {
        L.DomUtil.addClass(marker._icon, 'selected-marker');
      }
    }
  }
}
