document.addEventListener("turbo:load", function () {
  $('.user-chapter-checkbox').on('click', function(event) {
    const isChecked = $(this).is(':checked');
    const action = isChecked ? 'save_chapter' : 'forget_chapter'
    const id = $(this).val();
    const placeChildren = $(this).parent().parent().children().find(".select-user-story-place-checkbox");
    for (const child of placeChildren) {
      $(child).prop('checked', isChecked);
    }
    $.ajax({
      url: '/user_story_places/' + action,
      method: 'POST',
      dataType:'json',
      data: { chapter_id: id}
    })
    .done(function (data) {
      if (!data['success']) {
        alert(data['message']);
      };
    })
  })
});