document.addEventListener('turbo:load', function () {
  $('#story_place_ids').chosen({
    width: '400px',
    placeholder_text_multiple: 'Choose Places',
  });
  $('#story_story_region_ids').chosen({
    width: '400px',
    placeholder_text_multiple: 'Choose Story Regions',
  });
  $('#place_groupings_story_ids').chosen({
    width: '400px',
    placeholder_text_multiple: 'Choose Place Groupings',
  });
  $('#story_category_ids').chosen({
    width: '400px',
    placeholder_text_multiple: 'Choose Story Categories',
  });
  $('.searchable_dropdown').chosen({
    width: '100%',
    placeholder_text_multiple: 'Choose Categories',
  });

  $('body').on('click', '.favorite-button', function () {
    let firstChild = $(this).children()[0];
    let secondChild = $(this).children().eq(1);
    let url;
    let savingStory = firstChild.className == 'far fa-bookmark';
    if (savingStory) {
      url = this.dataset.saveUrl;
    } else {
      url = this.dataset.forgetUrl;
    }
    $.ajax({
      url: url,
      method: 'POST',
      contentType: 'application/json',
    }).done(function (data) {
      if (savingStory) {
        firstChild.className = 'fas fa-bookmark';
        secondChild.html('Forget Story');
      } else {
        firstChild.className = 'far fa-bookmark';
        secondChild.html('Save Story');
      }
      const checkboxes = $('.favorite-button').parent().parent().siblings('.user-story-places').children().find(':checkbox');
      for (const checkbox of checkboxes) {
        $(checkbox).prop('checked', savingStory);
        $(checkbox).prop("disabled", !savingStory)
      }
    }).fail( function(xhr, textStatus, errorThrown) {
        alert(xhr.responseText);
    });
  });
});
