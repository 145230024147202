$(document).ready(function() {
  $('#user-edit-form').on('submit', function(e) {
    var password = $('#password-field-edit').val();
    var confirmation = $('#password-confirmation-field').val();
  
    if (password !== confirmation) {
      var errorDiv = $('<div class="field_with_errors"></div>').text('Password and confirmation do not match');
      $('#password-error').empty().append(errorDiv);
      return false; // Prevent form submission
      return;
    } else {
      $('#password-error').text(''); // Clear the error message when passwords match
    }

    e.preventDefault();

    $.ajax({
      url: '/users',
      type: 'PUT',
      data: $("#user-edit-form").serialize(),
      success: function(response) {
        if (response.success) {
          window.location.href = response.redirect_url;
        }
      },
      error: function(response) {
        var errors = response.responseJSON.errors;
        // Clear any old errors
        $('.field_with_errors').remove();
      
        // Display the new errors
        for (var field in errors)  {
          var fieldErrors = errors[field];
          var errorMessages = field.charAt(0).toUpperCase() + field.slice(1) + ' ' + fieldErrors; // Prepend the field name
          var errorDiv = $('<div class="field_with_errors"></div>').text(errorMessages);
          if (field === 'password') {
            $('#password-error').empty().append(errorDiv);
          } else {
            $('#' + field).after(errorDiv);
          }
        }
      }
    });
  });
});
